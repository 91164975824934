<template>
  <Dialog ref="dialog" :title="title" :color="'error'" :maxWidth="'90vw'">
    <v-container>
      <div class="text-center" style="margin: 0 auto;">
        <p>{{ message }}</p>
      </div>
      <template v-if="records.length > 0">
        <v-data-table
          calculate-widths
          class="elevation-1"
          :headers="headers"
          :items="records"
          :options.sync="options"
          :footer-props="{
            itemsPerPageText: '1ページあたりの行数',
            itemsPerPageOptions: itemsPerPageOptions,
          }"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td
                v-for="(value, index) in item"
                :key="index"
                :class="value.class"
              >
                {{ value.value }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-container>
    <template v-slot:footer>
      <v-btn width="25%" class="back" dark @click="close()">
        {{ $t('button.close') }}
      </v-btn>
      <v-btn
        width="25%"
        class="next"
        :dark="isUpdateButtonEnabled"
        :disabled="!isUpdateButtonEnabled"
        @click="update()"
      >
        {{ $t('button.update') }}
      </v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  components: { Dialog, ErrorDialog },
  data() {
    return {
      title: '',
      message: '',
      headers: [],
      records: [],
      options: {},
      itemsPerPageOptions: '',

      // 更新ボタンが有効であるかどうか
      isUpdateButtonEnabled: true,
    };
  },
  methods: {
    open(title, message, headers, records, itemsPerPageOptions) {
      this.$set(this, 'isUpdateButtonEnabled', true);
      (this.title = title),
        (this.message = message),
        (this.headers = headers),
        (this.records = records),
        (this.options.page = 1),
        (this.itemsPerPageOptions = itemsPerPageOptions),
        this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    update() {
      this.$set(this, 'isUpdateButtonEnabled', false);
      this.$emit('onSuccess');
    },
    onError() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.userUpdateError'),
        this.$t('error.userUpdateBadRequest')
      );
      this.$set(this, 'isUpdateButtonEnabled', true);
    },
  },
};
</script>

<style>
.v-data-table td.update {
  color: red;
}
</style>
