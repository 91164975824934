var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.title, color: "error", maxWidth: "90vw" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "25%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("button.cancel")) + "\n    "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  attrs: {
                    width: "25%",
                    dark: _vm.isRegisterButtonEnabled,
                    disabled: !_vm.isRegisterButtonEnabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.register()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("button.register")) + "\n    "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        [
          _c(
            "div",
            { staticClass: "text-center", staticStyle: { margin: "0 auto" } },
            [_c("p", [_vm._v(_vm._s(_vm.message))])]
          ),
          _vm.records.length > 0
            ? [
                _c("v-data-table", {
                  staticClass: "elevation-1",
                  attrs: {
                    "calculate-widths": "",
                    headers: _vm.headers,
                    items: _vm.records,
                    options: _vm.options,
                    "footer-props": {
                      itemsPerPageText: "1ページあたりの行数",
                      itemsPerPageOptions: _vm.itemsPerPageOptions
                    }
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.options = $event
                    }
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }